<template>
  <div>
    <div class="flex countdown-wrapper">
      <SideCountDown @countdownEnded="redirectAfterTimer" v-if="idle" :seconds="30" delay="4" class="countdown-circle-side" />
    </div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss">

.countdown-wrapper {
  position: absolute;
  top: 100px;
  left: 100px;
  z-index: 99999;
}

.countdown-circle-side {
  .countdown-text {
    bottom: 0px;
    transform: scale(1);
    top: 27px;
    text-align: center;
    color: #ffffff
  }
  .circle {
    background-color: #9678D3;
    opacity: 1 !important;
  }
  .l-half::before, .r-half::before {
    border-color: #9678D3 !important;
    opacity: 1 !important;
  }
}
</style>

<script>
import CONST from '@/utils/Constants'
import ApiHelper from "@/helpers/ApiHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import SideCountDown from '@/components/SideCountdown.vue'

export default {
  name: 'timer-layout',
  components: {
    SideCountDown
  },
  data() {
    return {
      idle: false
    }
  },
  methods: {
    setDefaultBackgroundLargeScreen () {

      let data = {
        "message": this.getEmsDataPropertyText('global_background_image'),
        "channel": CONST.PUSHER.CHANNEL,
        "event-name": CONST.EVENT.BACKGROUND.SELECTED,
      }

      ApiHelper.post(CONST.API.BACKGROUND.SELECT, data, {headersType: 'basic'})
      .catch(error => {
          ErrorHelper.catch(error, this);
      });
    },
    redirectAfterTimer() {
      this.setDefaultBackgroundLargeScreen();
      this.$router.push({name: 'home'});
    }
  },
  onIdle() {
    this.idle = true
  },
  onActive() {
    this.idle = false
  }
}
</script>